import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["markInput", "eventSelect", "submit", "error", "genderInput", "seasonTypeInput"]

  connect() {
    // Set initial active states and values
    const seasonType = this.seasonTypeInputTarget.value || 'indoor'
    const gender = this.genderInputTarget.value || 'm'
    
    this.seasonTypeInputTarget.value = seasonType
    this.genderInputTarget.value = gender
    
    // Set initial button states
    this.updateButtonStates('season_type', seasonType)
    this.updateButtonStates('gender', gender)
    
    // Initialize selectpicker on page load
    requestAnimationFrame(() => {
      $('.selectpicker').selectpicker({
        dropupAuto: false,
        style: 'form-select'
      })
      // Force a refresh after initialization
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh')
      }, 0)
    })
    
    // Add venue change listener
    document.getElementById('venue_hnd')?.addEventListener('change', () => {
      if (this.errorTarget.textContent === "Please select a venue") {
        this.errorTarget.textContent = ""
      }
    })
  }

  validateMark(event) {
    const mark = this.markInputTarget.value.trim()
    const selectedOption = this.eventSelectTarget.options[this.eventSelectTarget.selectedIndex]
    const measure = selectedOption?.dataset?.measure
    const venue = document.getElementById('venue_hnd').value
    
    // If no venue is selected
    if (!venue) {
      this.errorTarget.textContent = "Please select a venue"
      if (event?.type === 'submit') {
        event.preventDefault()
      }
      return
    }
    
    // If mark is empty
    if (!mark) {
      // Only show error if user has interacted with the field
      if (event?.type === 'submit' || this.markInputTarget.classList.contains('is-invalid')) {
        this.markInputTarget.classList.add('is-invalid')
        this.errorTarget.textContent = "Please enter a mark"
      }
      if (event?.type === 'submit') {
        event.preventDefault()
      }
      return
    }
    
    const isValid = this.isValidMark(mark, measure)
    
    this.markInputTarget.classList.toggle('is-invalid', !isValid)
    
    if (!isValid) {
      if (measure === 'T') {
        this.errorTarget.textContent = "Invalid time format. Please enter a valid time (e.g. 3:45.67 or 17:00)."
      } else if (measure === 'D') {
        this.errorTarget.textContent = "Invalid distance format. Please enter a valid metric distance (e.g. 2.05) or imperial measurement (e.g. 6' 4\")."
      }
      if (event?.type === 'submit') {
        event.preventDefault()
      }
    } else {
      this.errorTarget.textContent = ""
    }
  }

  isValidMark(mark, measure) {
    if (!mark) return false
    if (!measure) return false

    if (measure === 'T') {
      // Split into parts
      const parts = mark.split(':')
      let minutes = 0
      let seconds = 0
      
      if (parts.length === 2) {
        // Format: m:ss or m:ss.xx
        minutes = parseInt(parts[0])
        if (parts[1].includes('.')) {
          const secondParts = parts[1].split('.')
          if (secondParts.length !== 2) return false
          seconds = parseFloat(parts[1])
          // Check that hundredths are properly formatted (2 digits)
          if (secondParts[1].length !== 2) return false
        } else {
          seconds = parseInt(parts[1])
        }
      } else if (parts.length === 1) {
        // Format: ss.xx
        const secondParts = parts[0].split('.')
        if (secondParts.length !== 2) return false
        seconds = parseFloat(parts[0])
        // Check that hundredths are properly formatted (2 digits)
        if (secondParts[1].length !== 2) return false
      } else {
        return false
      }
      
      // Validate components
      if (minutes > 59) return false
      if (seconds >= 60) return false
      if (seconds < 0) return false
      
      return true
    } else if (measure === 'D') {
      // Metric format: xx.xx
      if (/^\d+(\.\d{1,2})?$/.test(mark)) return true
      
      // Imperial format: x' or x' y" or x'y"
      return /^\d+'\s*(?:\d+(\.\d{1,2})?(?:"|'')?)?$/.test(mark)
    }

    return false
  }

  updateButtonStates(type, value) {
    const buttons = this.element.querySelectorAll(`[id^="${type}_"][id$="_button"]`)
    buttons.forEach(btn => {
      const isSelected = btn.dataset.value === value
      btn.classList.remove('btn-left', 'btn-right')
      btn.classList.add(isSelected ? 'btn-right' : 'btn-left')
    })
  }

  updateSeasonType(event) {
    event.preventDefault()
    const button = event.currentTarget
    const value = button.dataset.value
    
    // Update hidden input
    this.seasonTypeInputTarget.value = value
    
    // Update button states
    this.updateButtonStates('season_type', value)

    // Update both frames
    fetch(`/conversion?season_type=${value}&gender=${this.genderInputTarget.value}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
        // Wait for the DOM to update
        requestAnimationFrame(() => {
          // Initialize selectpicker
          $('.selectpicker').selectpicker({
            dropupAuto: false
          })
          // Force a refresh after initialization
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh')
          }, 0)
        })
      })
  }

  updateGender(event) {
    event.preventDefault()
    const button = event.currentTarget
    const value = button.dataset.value
    
    // Update hidden input
    this.genderInputTarget.value = value
    
    // Update button states
    this.updateButtonStates('gender', value)

    // Store current venue selection
    const currentVenue = document.getElementById('venue_hnd').value

    // Update both frames
    fetch(`/conversion?gender=${value}&season_type=${this.seasonTypeInputTarget.value}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
        // Wait for the DOM to update
        requestAnimationFrame(() => {
          // Restore venue selection
          if (currentVenue) {
            document.getElementById('venue_hnd').value = currentVenue
          }
          // Initialize selectpicker
          $('.selectpicker').selectpicker({
            dropupAuto: false,
            style: 'form-select'
          })
          // Force a refresh after initialization
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh')
          }, 0)
        })
      })
  }
} 